/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import ParseContent from 'components/shared/ParseContent'
import HeroBare from 'components/elements/HeroBare'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

// Third Party
import styled from 'styled-components'


const Content = styled(ParseContent)`
    background-color: ${props => props.theme.color.face.light};
    padding: 50px;
    box-shadow: 0 0 8px rgba(0,0,0,0.3);

    h2 {
        font-weight: ${props => props.theme.font.weight.xl};
        font-size: ${props => props.theme.font.size['30']};
    }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <HeroBare fields={acf.banner} />

      <div className="container pb-lg-5 pb-3">
        <Content content={acf.description} />
      </div>

      <div className="py-5" />
      <div className="py-5" />
      <div className="py-5" />

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          background {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        description
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
