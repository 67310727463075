/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image'

const Hero = styled.div`
  @media (min-width: 992px) {
    min-height: 150px;
  }

  @media (max-width: 991px) {
    min-height: 150px;
  }
`

const ImageBackground = styled(Image)``

const Fade = styled.div`
  background-color: ${props => props.theme.color.face.main};
  z-index: -1;

  ${ImageBackground} {
    max-height: 800px;
    min-height: 300px;
    height: 100%;
    width: 100%;
    opacity: 0.10;

    @media screen and (max-width: 767px) {
      max-height: 1000px;
    }
  }
`

const Background = styled.div`
  height: 800px;
  width: 100%;
  position: absolute;

  @media screen and (max-width: 991px) {
    height: 600px;
  }

  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
    height: 1000px;
  }
`

const HeroDefault = ({ fields }) => (
  <Hero>
    <Background className="row mx-0">
      <Fade className="col-md-6 px-0">
        <ImageBackground src={fields.background} aboveFold />
      </Fade>
    </Background>
  </Hero>
)

export default HeroDefault